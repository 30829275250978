#animationOuter {
    position: absolute;
    will-change: transform;
    align-items: center;
}

#animationInner {
    background-color: rgb(0, 0, 0, 0.5);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 36vh;
    height: 50vh;
    min-width: 280;
    min-height: 400;
    will-change: transform;
    border-radius: 10px;
    margin: 10px auto;
    box-shadow: 0 0px 50px 0px rgba(00, 00, 00, 0.25);
}

